import React from 'react';
import { Typography } from '@mui/material';
import MailLogStatus from '../components/states/mailLogState';

const useMarketingCampaignContactColumns = () => [
  {
    id: 'contact_Email',
    field: 'contact_Email',
    headerName: 'email',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {params.row.mailLog.to}
      </Typography>
    ),
  },
  {
    id: 'contact_Name',
    field: 'contact_Name',
    headerName: 'Nombre',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {params.row.contact?.name || 'Sin contacto'}
      </Typography>
    ),
  },
  {
    id: 'contact_MasterEntity_Name',
    field: 'contact_MasterEntity_Name',
    headerName: 'Empresa',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {params.row.contact?.masterEntity?.name}
      </Typography>
    ),
  },
  {
    id: 'lastStatus',
    field: 'lastStatus',
    headerName: 'Último evento',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <MailLogStatus mailLog={params.row.mailLog} />
    ),
  },
];

export default useMarketingCampaignContactColumns;
