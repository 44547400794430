import React, { useMemo } from 'react';
import { LoggedInNavItems } from '@fingo/lib/components/layout/navbar';
import { Stack } from '@mui/material';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { NavbarLink } from '@fingo/lib/components/links';
import { logOut } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { useApolloClient } from '@apollo/client';
import useLucilaRoutes from '../../../hooks/useLucilaRoutes';

const LoggedInDesktopNavItems = () => {
  const client = useApolloClient();
  const lucilaRoutes = useLucilaRoutes();
  const country = useGetCountryFromUrl();
  const routes = useMemo(
    () => lucilaRoutes.filter(({ showInNavbar }) => !!showInNavbar),
    [country],
  );

  return (
    <Stack
      width="100%"
      direction="row"
      spacing={4}
      height={55}
      alignItems="center"
      justifyContent="space-between"
      ml={4}
    >
      <LoggedInNavItems routes={routes} />
      <Stack direction="row" spacing={1}>
        <NavbarLink
          id="logout"
          title="Cerrar sesión"
          onClick={() => { logOut(client); }}
        />
      </Stack>
    </Stack>
  );
};

export default LoggedInDesktopNavItems;
