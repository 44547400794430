import { Typography } from '@mui/material';
import React from 'react';
import { CompanyProfileCell, FolioWithProfileAndAlerts } from '@fingo/lib/components/cells';
import { getTimeDiffWithHoursMinutes } from '@fingo/lib/helpers';
import { StatusSummaryStepper, Status } from '@fingo/lib/components/dataGridCells';
import OperationalAlertsArray from '@fingo/lib/components/cells/OperationalAlertsArray';

const useRatificationColumns = () => [
  {
    field: 'folio',
    headerName: 'Folio',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    flex: 0.2,
    renderCell: ({ row }) => (
      <FolioWithProfileAndAlerts invoice={row} AlertsArray={OperationalAlertsArray} />
    ),
  },
  {
    field: 'company_MasterEntity_Name',
    headerName: 'Emisor',
    type: 'string',
    flex: 0.5,
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row.company.masterEntity} />,
  },
  {
    field: 'receiver_Ratification',
    headerName: 'Receptor',
    type: 'string',
    flex: 0.5,
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row.receiver} type="receiver" showDicom />,
  },
  {
    field: 'siiStatus',
    headerName: 'Estado',
    headerAlign: 'left',
    sortable: false,
    filterable: false,
    flex: 0.3,
    type: 'singleSelect',
    align: 'center',
    renderCell: ({ row }) => <StatusSummaryStepper invoice={row} />,
  },
  {
    field: 'ratificationManagerTimeDiff',
    headerName: 'Tiempo Ratificación',
    type: 'string',
    sortable: true,
    flex: 0.3,
    renderCell: ({ row }) => (
      <Typography variant="body2">{getTimeDiffWithHoursMinutes(row.ratificationmanager.assignedPriorityDatetime)}</Typography>
    ),
  },
  {
    field: 'operationTimeDiff',
    headerName: 'Tiempo Operación',
    type: 'string',
    sortable: true,
    flex: 0.3,
    renderCell: ({ row }) => (
      <Typography variant="body2">{getTimeDiffWithHoursMinutes(row.ratificationmanager.createdAt)}</Typography>
    ),
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado',
    sortable: true,
    renderCell: ({ row }) => <Status status={row.status} oneStepper />,
    flex: 0.3,
  },
];

export default useRatificationColumns;
