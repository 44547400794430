import React from 'react';
import { Link } from 'react-router-dom';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { useBooleanState, useIsLogged, useGetUser } from '@fingo/lib/hooks';
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { makeStyles, styled } from '@mui/styles';
import LoggedOutMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedOutMobileNavItems';
import LoggedInMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedInMobileNavItems';
import { LucilaLogo } from '../../../assets';
import useLucilaRoutes from '../../../hooks/useLucilaRoutes';

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MobileNavbar = () => {
  const routes = useLucilaRoutes().filter(({ showInNavbar }) => showInNavbar);
  const user = useGetUser();
  const isLogged = useIsLogged();
  const [drawerOpen, toggleDrawer] = useBooleanState(false);
  const classes = useStyles();
  return (
    <>
      <FingoNavbar>
        <Stack direction="row" width="100%" height="100%" alignItems="center">
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
            <Menu color="primary" />
          </IconButton>
          <Box component={Link} to="/" sx={{ ml: 'auto' }}>
            <img src={LucilaLogo} alt="logo" className={classes.logo} />
          </Box>
        </Stack>
      </FingoNavbar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 280,
            bgcolor: 'text.main',
            pb: 4,
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            <Close color="fingoWhite" />
          </IconButton>
        </DrawerHeader>
        {isLogged && user && <LoggedInMobileNavItems closeDrawer={toggleDrawer} routes={routes} />}
        {!isLogged && <LoggedOutMobileNavItems withRegister={false} />}
      </Drawer>
    </>
  );
};

export default MobileNavbar;
