import React from 'react';
import { formatDateTime, formatDayMonthYear, snakeToCamelWithSpaces } from '@fingo/lib/helpers';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import StatusTrafficLight from '@fingo/lib/components/dataDisplay/StatusTrafficLight';

const useMarketingCampaignColumns = () => [
  {
    id: 'id',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Link
        variant="body1"
        underline="hover"
        component={RouterLink}
        to={(location) => `${location.pathname}/${params.row.id}`}
      >
        Ver
      </Link>
    ),
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Nombre del Correo',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
      >
        {snakeToCamelWithSpaces(row?.mail.emailName)} ({row?.country.name})
      </Typography>
    ),
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    headerName: 'Creado',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {formatDayMonthYear(params.row.createdAt)}
      </Typography>
    ),
  },
  {
    id: 'scheduledMailingDate',
    field: 'scheduledMailingDate',
    headerName: 'Fecha programada de envío',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {formatDateTime(params.row.scheduledMailingDate)}
      </Typography>
    ),
  },
  {
    id: 'fromMail',
    field: 'fromMail',
    headerName: 'Desde',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
      >
        {params.row.fromMail}
      </Typography>
    ),
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Enviado',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => <StatusTrafficLight value={row.status?.status} />,
  },
];

export default useMarketingCampaignColumns;
