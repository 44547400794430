import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Grid, CircularProgress } from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  GET_COLLECTION_MAIL_PREVIEW,
  GET_RATIFICATION_MAIL_PREVIEW,
} from '@fingo/lib/graphql';

const getPreviewMutationMapper = {
  RATIFICATION: GET_RATIFICATION_MAIL_PREVIEW,
  COLLECTION: GET_COLLECTION_MAIL_PREVIEW,
};

const OperativeMailPreview = ({ operationType, managersIds, mailType }) => {
  const GET_PREVIEW_MUTATION = getPreviewMutationMapper[operationType];
  const [preview, setPreview] = useState();
  const [getPreview] = useMutation(GET_PREVIEW_MUTATION, {
    onCompleted: (data) => setPreview(Object.values(data)[0].preview),
  });
  useEffect(() => {
    getPreview({
      variables: {
        managersIds,
        mailType,
      },
    });
  }, []);
  if (!preview) {
    return (
      <Grid container justifyContent="center" padding={2}>
        <CircularProgress />
      </Grid>
    );
  }
  return <Grid>{parse(preview)}</Grid>;
};

OperativeMailPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  managersIds: PropTypes.array.isRequired,
  mailType: PropTypes.string.isRequired,
  operationType: PropTypes.string.isRequired,
};

export default OperativeMailPreview;
